import React, { memo, RefObject, useRef } from 'react';
import { Gutter, Image, Link, Text } from '~/shared/components';
import { Carousel } from '~/shared/components/Carousel';
import Flex from '~/shared/components/Flex';
import FlexContent from '~/shared/components/FlexGrowContent';
import { M21ReferencedCategoriesModule, PageReference } from '~/lib/data-contract';
import { weakKey } from '~/shared/utils/jsx';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import {
    StyledCategory,
    StyledImageContainer,
    StyledInfoContainer,
    StyledButton,
    Headline,
    SubHeadline,
} from './styled';
import { useTheme } from '@emotion/react';
import ChevronLeft from '$icons/chevron-left.svg';
import ChevronRight from '$icons/chevron.svg';
import { ModuleHeader } from '../ModuleHeader';
import { breakpointsRaw, queries } from '~/theme/breakpoints';

export type M21ReferencedCategoriesProps = M21ReferencedCategoriesModule;

/**
 * List of page references, extracts data from references field in CMS.
 * The layout is supposed to be used with presentational images and text which describes the page.
 */
export const M21ReferencedCategories = memo(function M21ReferencedCategories({
    items,
    headline,
    subHeadline,
    callToAction,
    priority,
    textColor,
    ...rest
}: M21ReferencedCategoriesProps) {
    const leftArrow = useRef<HTMLButtonElement>(null);
    const rightArrow = useRef<HTMLButtonElement>(null);

    return (
        <>
            {!!items?.length && (
                <ModuleContainer {...rest}>
                    <Gutter gutterSize="small">
                        <Flex alignItems="start" css={{ color: textColor }}>
                            <StyledButton
                                css={{ paddingLeft: 8, paddingRight: 8 }}
                                variant="icon"
                                ref={leftArrow}
                            >
                                <ChevronLeft />
                            </StyledButton>
                            <FlexContent grow={1}>
                                <ModuleHeader
                                    noGutter
                                    headline={headline}
                                    subHeadline={subHeadline}
                                    link={callToAction}
                                    textColor={textColor}
                                />
                            </FlexContent>
                            <StyledButton
                                css={{ paddingLeft: 8, paddingRight: 8 }}
                                variant="icon"
                                ref={rightArrow}
                            >
                                <ChevronRight />
                            </StyledButton>
                        </Flex>
                    </Gutter>
                    <CategoryCarousel
                        leftArrow={leftArrow}
                        rightArrow={rightArrow}
                        priority={priority}
                        items={items}
                        textColor={textColor}
                    />
                </ModuleContainer>
            )}
        </>
    );
});

// In case we need the "list" version of the component, we can use this one:
// const List = (props: { items: PageReference[] }) => {
//     const { items } = props;
//     const { sizes } = useTheme();
//     const columnCount = Math.min(items?.length || 0, 5);
//     const imagesVW = 100 / columnCount;
//     const imagesMaxWidth = sizes.contentMaxWidth / columnCount;

//     return (
//         <StyledCategories>
//             {items?.map((item) => (
//                 <Card
//                     key={weakKey(item)}
//                     item={item}
//                     imagesVW={imagesVW}
//                     imagesMaxWidth={imagesMaxWidth}
//                 />
//             ))}
//         </StyledCategories>
//     );
// };

type CarouselProps = {
    items: PageReference[];
    priority?: boolean;
    leftArrow?: RefObject<HTMLButtonElement>;
    rightArrow?: RefObject<HTMLButtonElement>;
    textColor?: string;
};

function CategoryCarousel(props: CarouselProps) {
    const { items, priority, leftArrow, rightArrow, textColor } = props;
    const { sizes } = useTheme();
    const columnCount = Math.min(items?.length || 0, 4);
    const imagesVW = 100 / columnCount;
    const imagesMaxWidth = sizes.contentMaxWidth / columnCount;
    return (
        <Carousel
            items={items?.map((item) => (
                <Card
                    key={weakKey(item)}
                    item={item}
                    priority={priority}
                    imagesVW={imagesVW}
                    textColor={textColor}
                    imagesMaxWidth={imagesMaxWidth}
                />
            ))}
            options={{
                slidesPerView: 1.3,
                breakpoints: {
                    [breakpointsRaw.md]: {
                        slidesPerView: 3.8,
                    },
                },
            }}
            prevRef={leftArrow}
            nextRef={rightArrow}
        ></Carousel>
    );
}

function Card(props: {
    item: PageReference;
    imagesVW: number;
    imagesMaxWidth: number;
    priority?: boolean;
    textColor?: string;
}): JSX.Element {
    const { item, imagesVW, imagesMaxWidth, priority, textColor } = props;
    return (
        <StyledCategory>
            <Link
                href={item.callToAction?.url}
                target={item.callToAction?.target}
                title={item.callToAction?.title}
                noHover
            >
                {item.pageImage?.src ? (
                    <StyledImageContainer>
                        <Image
                            src={item.pageImage.src}
                            alt={item.pageImage.name}
                            priority={priority}
                            layout="responsive"
                            height={456}
                            width={367}
                            objectPosition="center"
                            objectFit="cover"
                            sizes={`${queries.xs} 100vw, ${queries.sm} 50vw, ${queries.contentMax} ${imagesVW}vw,  ${imagesMaxWidth}px`}
                            focalPoint={item.pageImage.focalPoint}
                        />
                    </StyledImageContainer>
                ) : null}
                <StyledInfoContainer>
                    <Text variant="bodySmall">{item.parentName}</Text>
                    <Headline color={textColor} variant="display3">
                        {item.pageTitle}
                    </Headline>
                    <SubHeadline color={textColor} variant="body">
                        {item.pageDescription}
                    </SubHeadline>
                </StyledInfoContainer>
            </Link>
        </StyledCategory>
    );
}
